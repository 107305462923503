<template>
  <v-layout row justify-center>
    <v-flex xs12
      ><v-card>
        <v-flex xs12>
          <v-card>
            <v-container fluid grid-list-md>
              <v-card color="white">
                <v-layout row>

                  <v-text-field
                    tabindex="1"
                    class="mt-0 fs-25px"
                    flat
                    hide-details
                    label="REG No."
                    prepend-inner-icon="search"
                    single-line
                    solo
                    v-model="reg_no"
                    ref="reg_no_search"
                  ></v-text-field>
                  <v-card-actions>
                    <v-btn color="info" dark @click="searchStudent"
                      >Add New</v-btn
                    >
                  </v-card-actions>
                </v-layout>
              </v-card>
            </v-container>
          </v-card>
        </v-flex>
        <v-layout row>
          <v-flex
            xs4
            class="pr-1"
            v-if="getAdminFormFillUpSubject.form_fill_up"
          >
            <v-card>
              <v-container fluid grid-list-md>
                <StudentInfo />
                <FormFillUpFee />
              </v-container>
            </v-card>
          </v-flex>
          <v-flex xs8>
            <v-card>
              <v-container
                fluid
                grid-list-md
                v-if="getAdminFormFillUpSubject.total_subject"
              >
                <compulsorySubject
                  v-if="getAdminFormFillUpSubject.compulsory_subject.length > 0"
                />
                <electiveSubject
                  v-if="
                    Object.keys(getAdminFormFillUpSubject.elective_subject)
                      .length
                  "
                />
                <optionalSubject
                  v-if="getAdminFormFillUpSubject.optional_subject.length > 0"
                />
                <improvementSubject
                  v-if="getAdminFormFillUpSubject.improvement_subject.length > 0"
                />
                <!-- <v-toolbar flat dark>
                  <v-toolbar-title>Update Mobile No.</v-toolbar-title>
                </v-toolbar> -->
                <!-- <v-card>
                  <v-card-text>
                    <v-form>
                      <v-text-field
                        name="mobile"
                        v-model="mobile"
                        label="Mobile No."
                        v-validate="'required'"
                        :error-messages="errors.collect('mobile')"
                        data-vv-name="mobile"
                        required
                        prepend-icon="edit"
                      ></v-text-field>
                    </v-form>
                  </v-card-text>
                </v-card> -->
                <!-- <div class="justify-center layout px-0 mt-2">
                  <v-btn color="success" @click="save">Submit</v-btn>
                </div> -->
              </v-container>
            </v-card>
          </v-flex>
        </v-layout>
      </v-card></v-flex>
      <pre>
        <!-- {{ getAdminFormFillUpSubject.compulsory_subject }} -->
      </pre>
  </v-layout>
</template>

  <!-- Special Form Fillup List -->
  <!-- <template >
    <specialFormFillupList ></specialFormFillupList>
  </template> -->
  <!-- Close Special Form Fillup List -->

<script>
import StudentInfo from "@/components/probable/specialFormFillup/student_info";
import FormFillUpFee from "@/components/probable/specialFormFillup/FormFillUpFee";
import compulsorySubject from "@/components/probable/specialFormFillup/compulsory_subject";
import electiveSubject from "@/components/probable/specialFormFillup/elective_subject";
import improvementSubject from "@/components/probable/specialFormFillup/improvement_subject";
import optionalSubject from "@/components/probable/specialFormFillup/optional_subject";

export default {
  name: "SPFormFillup",
  components: {
    StudentInfo,
    FormFillUpFee,
    compulsorySubject,
    electiveSubject,
    improvementSubject,
    optionalSubject,
    // specialFormFillupList
  },
  computed: {
    formFillUpInfo() {
      return this.$store.getters.getPblStudentInfo;
    },
    getLoggedInExam() {
      return this.$store.getters.getLoggedInExam;
    },
    getLoggedInYear() {
      return this.$store.getters.getLoggedInYear;
    },
    getSchema() {
      return this.$store.getters.formFillUpConfirmByAdmin;
    },
    getAdminFormFillUpSubject() {
      return this.$store.getters.getAdminFormFillUpSubject;
    },
    getAdminId(){
      return this.$store.getters.getAuthUserID;
    }
  },
  data: () => ({
    dialog: false,
    reg_no: "",
    mobile: "",
    form_fill_up_id: null,
    fees: {},
    selected: {
      compulsory_subject: [],
      elective_subject: [],
      improvement_subject: [],
      optional_subject: []
    },
    confirm_by_student: []
  }),
  created() {
    // console.log("reg_no", this.reg_no);
    // this.$store.dispatch("getAdminFormFillUpSubject", {
    //   form_fill_up_id: this.formFillUpInfo.form_fill_up.id,
    //   reg_no: this.reg_no
    // });
  },

  methods: {
    searchStudent() {
      let reg_no = this.reg_no;
      this.$store
        .dispatch("getPblStudentInfo", {
          reg_no: reg_no,
          exam_code: this.getLoggedInExam.code,
          exam_year: this.getLoggedInYear.year
        })
        .then((response) => {
          this.$store.dispatch("getAdminFormFillUpSubject", {
            form_fill_up_id: this.formFillUpInfo.form_fill_up.id,
            reg_no: this.reg_no
          });
        });
    },
    calculateFeePerStudentAdmin(options) {
      this.$store.dispatch("calculateFeePerStudentAdmin", options);
    },
    confirmSubject: function(selected) {
      this.confirm_by_student = [];
      let all_subjects = [];
      Object.keys(selected).forEach(element => {
        let subjects = this.selected[element];
        subjects.forEach(element => {
          all_subjects.push({
            paper_code: element.paper_code,
            incourse_marks: element.incourse_marks,
            form_fill_up_id: element.form_fill_up_id,
            form_fill_up_details_id: element.id
          });
        });
      });
      this.confirm_by_student = all_subjects;
      this.calculateFeePerStudentAdmin({
        form_fill_up_id: this.formFillUpInfo.form_fill_up.id,
        paper_code: all_subjects,
      });
    },
    save() {
      let self = this;
      self.$root.$confirm("Are You Sure To Submit ?").then(confirm => {
        if (confirm) {
          let item = self.getSchema;
          let student_info = {
            reg_no: this.formFillUpInfo.form_fill_up.reg_no,
            mobile: self.mobile
          };

          const formData = Object.assign(self.getSchema, {
            confirm_subjects: self.confirm_by_student,
            form_fill_up_id: this.formFillUpInfo.form_fill_up.id,
            fees: this.fees,
            student_info: student_info,
            admin_id: this.getAdminId
          });
          const isEmpty = !Object.values(item).some(
            x => x !== null && x !== ""
          );
          // console.log('formdata',formData)
          if (!isEmpty) {
            self.$store
              .dispatch("formFillUpConfirmAdmin", formData)
              .then(function(data) {              
              })
              .catch(function(error) {
                console.log(error);
                // self.$store.dispatch("fetchAllExamsnData");
              });
          } else {
            // console.log("empty");
          }
        }
      });
    }
  },
  watch: {
    "selected.compulsory_subject": function(val, oldval) {
      this.confirmSubject(this.selected);
    },
    "selected.elective_subject": function(val, oldval) {
      this.confirmSubject(this.selected);
    },
    "selected.improvement_subject": function(val, oldval) {
      this.confirmSubject(this.selected);
    },
    "selected.optional_subject": function(val, oldval) {
      this.confirmSubject(this.selected);
    },
    confirm_by_student: function(val, oldval) {}
  }
};
</script>
